import { GBDropdownElements } from "components/basic/Dropdown/GBDropdown";
import { Translate } from "./Language";

export const DropDownLists = {
    getGenderList() : GBDropdownElements[]{
        let listToReturn : GBDropdownElements[] = [];
        listToReturn.push(new GBDropdownElements(Translate("male", "Male"), false, "M"));
        listToReturn.push(new GBDropdownElements(Translate("female", "Female"), false, "F"));
        return listToReturn
    }
}