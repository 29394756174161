import React, { Fragment } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import NullCheck from 'utils/NullCheck';
import ArrayHelper from 'utils/ArrayHelper';
import { Translate } from 'utils/Language';
import GBLabelPair from '../LabelPair/GBLabelPair';
import Seperator from '../Seperator/Seperator';
import { GetHash } from 'utils/Generator';

export class GBDropdownElements {
    IsHeader?: boolean;
    Value?: string;
    DisplayName: string;
    Group?: string;
    selected: boolean;
    constructor(DisplayName: string, IsHeader: boolean, Value: string, selected?: boolean, Group?: string) {
        this.DisplayName = DisplayName;
        this.IsHeader = IsHeader;
        this.Value = Value;
        this.Group = Group;
        this.selected = selected;
        if (Group === undefined || Group === null) {
            this.Group = "none";
        }
    }
}

export interface GBDropdownProps {
    Id?: string;
    DefaultDisplayName?: string;
    Elements: GBDropdownElements[];
    selectionFunction?: Function;
    className?: string;
    errorMessage?: string;
    allowSearch?: boolean;
}

export interface GBDropdownstate {
    status: string;
    selectedValue: string;
    selectedDisplayName?: string;
    searchValue?: string;
}

export default class GBDropdown extends React.Component<GBDropdownProps, GBDropdownstate> {
    testString: string = "";
    Check = new NullCheck();
    formId = GetHash();
    
    constructor(props, context) {
        super(props, context);
        this.state = {
            status: "",
            selectedValue: "",
            selectedDisplayName: Translate("not_selected", "Not selected"),
            searchValue: ""
        };
    }

      

    render() {

        return (
            <div className={"gms-dropdown-wrapper " + (this.props.className !== undefined ? this.props.className : "") + (this.props.errorMessage ? " error" : "")}>
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" value={this.state.selectedValue}>
                        {(this.props.Elements !== undefined && this.props.Elements.find(e => e.selected) !== undefined) ? this.props.Elements.find(e => e.selected).DisplayName : this.state.selectedDisplayName}
                        {/* {this.Check.StringHasValue(this.state.selectedDisplayName) ? this.state.selectedDisplayName : this.props.DefaultDisplayName} */}
                    </Dropdown.Toggle>
                    <Fragment>
                        {this.props.allowSearch ?
                            <Dropdown.Menu >
                                <Fragment>
                                    <GBLabelPair className={"strip"} description={Translate("search_by", "Search by")}>
                                        <FormControl
                                            tabIndex={-1}
                                            autoFocus={true}
                                            className="flex-grow"
                                            placeholder="Type to filter..."
                                            onChange={(e) => this.setState({ searchValue: e.target.value })}
                                            value={this.state.searchValue}
                                        />

                                    </GBLabelPair>
                                    <div style={{ overflowY: 'auto' }}>

                                        {this.sortMenu(this.props.Elements).filter((e) => e.DisplayName.toLowerCase().startsWith(this.state.searchValue.toLowerCase())).map((element, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    {element.IsHeader ? (
                                                        <Dropdown.Header>{element.DisplayName}</Dropdown.Header>

                                                    ) : (

                                                            <Dropdown.Item  eventKey={this.formId+index.toString()} key={element.Value} onSelect={() => this.setItemAsSelected(element.Value, element.DisplayName)}>{element.DisplayName}</Dropdown.Item>
                                                        )}

                                                </Fragment>
                                            )
                                        })}
                                    </div>
                                </Fragment>

                            </Dropdown.Menu>
                            :
                            <Dropdown.Menu>
                                {this.sortMenu(this.props.Elements).map((element, index) => {
                                    return (
                                        <Fragment key={index}>
                                            {element.IsHeader ? (
                                                <Dropdown.Header>{element.DisplayName}</Dropdown.Header>

                                            ) : (

                                                    <Dropdown.Item key={element.Value} onSelect={() => this.setItemAsSelected(element.Value, element.DisplayName)}>{element.DisplayName}</Dropdown.Item>
                                                )}

                                        </Fragment>
                                    )
                                })}

                            </Dropdown.Menu>
                        }
                    </Fragment>
                </Dropdown>
                {new NullCheck().StringHasValue(this.props.errorMessage) &&
                    <label>{this.props.errorMessage}</label>
                }
            </div>
        );
    }
    setItemAsSelected(value, displayname) {
        if (this.props.selectionFunction !== undefined) {
            this.props.selectionFunction(value)
        } else {
            this.setState({ selectedValue: value, selectedDisplayName: displayname })

        }
    }
    filterByGroup = (list: GBDropdownElements[], group: string) => list.filter(element => element.Group === group);

    sortMenu(list): GBDropdownElements[] {
        let tempList: GBDropdownElements[] = [];
        let groups: string[] = [];
        //finds groups
        list.map(Ielement => {
            if (this.Check.StringHasValue(Ielement.Group)) {
                if (!groups.includes(Ielement.Group)) {
                    groups.push(Ielement.Group);
                }
            }
            return null;
        })

        //sort groups
        groups = groups.sort((one, two) => (one > two ? 1 : -1));
        if (groups.includes("none")) {
            groups = new ArrayHelper().array_move(groups, groups.indexOf("none"), 0)
        }

        //fills the new list with elements sorted by groups and displayname
        groups.map(e => {
            let temp: GBDropdownElements[] = this.filterByGroup(list, e);

            temp = temp.sort((one, two) => (one.DisplayName > two.DisplayName ? 1 : -1));
            if (temp.find(e => e.IsHeader === true) === undefined || temp.find(e => e.IsHeader === true) === null) {
                if (temp[0].Group !== "none") {
                    temp.push(new GBDropdownElements(temp[0].Group, true, "", temp[0].selected, temp[0].Group))
                }
            }
            let header = temp.find(e => e.IsHeader === true);
            temp = new ArrayHelper().array_move(temp, temp.indexOf(header), 0)
            temp.map(o => tempList.push(o));
            return null;
        })

        return tempList;
    }

}


// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
// const CustomMenu = React.forwardRef(
//     ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
//         const [value, setValue] = useState('');

//         return (
//             <div
//                 ref={ref}
//                 style={style}
//                 className={className}
//                 aria-labelledby={labeledBy}
//             >
//                 <FormControl
//                     autoFocus
//                     className="mx-3 my-2 w-auto"
//                     placeholder="Type to filter..."
//                     onChange={(e) => setValue(e.target.value)}
//                     value={value}
//                 />
//                 <ul className="list-unstyled">
//                     {React.Children.toArray(children).filter(
//                         (child) =>
//                             !value || child.props.children.toLowerCase().startsWith(value),
//                     )}
//                 </ul>
//             </div>
//         );
//     },
// );

