import GBGhostButton from 'components/basic/GhostButton/GBGhostButton';
import { PageIndex } from 'entitites/PageIndex';
import React, { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import { currentAuth } from 'routes/AllRoutes';
import AjaxHelper from 'utils/AjaxHelper';
import { Translate } from 'utils/Language';
import { Link } from 'react-router-dom';



export interface WaitingPageProps {
    Id?: string;
    history?: any;
}

export interface WaitingPagestate {
    status: string;
    backbutton: boolean;
}

export default class WaitingPage extends React.Component<WaitingPageProps, WaitingPagestate> {
    transactionGuid = null;
    startTime: Date = null;
    timer = null;
    backTimer = null;
    endTimer = null;
    constructor(props, context) {
        super(props, context);
        this.state = {
            status: "",
            backbutton: false
        };
        this.startTime = new Date();
        console.log(this.startTime);
        // this.startTime.
        this.tryFinished();
        this.endTries()
        this.addBackTimer()
        this.transactionGuid = JSON.parse(localStorage.getItem("tranactionGuid")).guid;
    }

    addBackTimer() {
        this.endTimer = setTimeout(()=>{
            clearTimeout(this.backTimer);
            this.setState({backbutton: true})
        },120000)
    }
    endTries() {
        this.endTimer = setTimeout(()=>{
            clearTimeout(this.timer);
            clearTimeout(this.backTimer);
            clearTimeout(this.endTimer);
            this.setState({status: Translate("waiting_timed_out","Waiting timed out")})
        },600000)
    }
    tryFinished() {
        this.timer = setTimeout(() => {
            new AjaxHelper().simplePost("/public/CheckPayment?handler=CheckStatus", { PaymentId: this.transactionGuid }, (e) => {
                let val = e.data;
                if (this.timer !== null) {

                    clearTimeout(this.timer);
                    this.timer = null;
                    if (val.success) {
                        if (val.data !== "") {
                            localStorage.setItem("Token", JSON.stringify(val.data.token));
                            currentAuth.authenticate(true);
                            this.props.history.push({
                                pathname: PageIndex.receipt,
                                search: '',
                                state: e.data.data
                            })
                        } else {
                            this.tryFinished()
                        }
                    } else {
                        this.setState({ status: val.message })
                    }
                }
            }, ()=>{
                clearTimeout(this.timer);
                this.tryFinished()
            });

        }, 2000)
    }
    componentWillUnmount() {
        if (this.timer !== null) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    render() {
        return (
            <div className={"flex-grow flex-column flex-center h-100"}>
                {this.state.status === "" ?
                    <Fragment>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        <label>{Translate("waiting_for_you_to_complete_the_paymentflow_in_GolfBox", "Waiting for you to complete the paymentflow in GolfBox")}</label>
                        <label>{Translate("if_you_cant_find_the_payment_window_please_check_for_a_new_window_in_your_browser", "If you can't find the payment window, please check a new window in your browser")}</label>
                        {this.state.backbutton && 
                            <Link className={"gms-button gms-first"} to={PageIndex.Home}>
                                <GBGhostButton className={"w-100"} onClick={()=>{
                                     clearTimeout(this.timer);
                                     clearTimeout(this.backTimer);
                                     clearTimeout(this.endTimer);
                                }} text={Translate("back", "Back")}></GBGhostButton>
                            </Link>
                        }
                    </Fragment>
                    :
                    <Fragment>
                        <label>{this.state.status}</label>
                        <Link className={"gms-button gms-first"} to={PageIndex.Home}>
                            <GBGhostButton className={"w-100"} text={Translate("click_here_to_start_over", "Click here to start over")}></GBGhostButton>
                        </Link>
                    </Fragment>
                }
            </div>
        );
    }

}

