import React, { Fragment } from 'react';
import { AllRoutes } from 'routes/AllRoutes';
import {Switch } from 'react-router-dom'

export interface ArticleProps {
  Id?: string;
}

export interface Articlestate {
  status: string;

}

export default class Article extends React.Component<ArticleProps, Articlestate> {
  testString: string = "";
  constructor(props, context) {
    super(props, context);
    this.state = {
      status: ""
    };
  }
  render() {
    return (
      <article>
        <div className={"body-container"}>
          <Switch>
            <Fragment>
              <AllRoutes></AllRoutes>
            </Fragment>
          </Switch>
        </div>
      </article>
    );
  }


}

