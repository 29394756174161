import React from 'react';
// import styles from './GBlabelPair.module.scss';
import { GBText } from 'utils/Language';



export interface GBLabelPairProps {
  forId?: string;
  description: string;
  isloading?: boolean;
  error?: boolean;
  className?:string;
  strip?: boolean;
 }


export default class GBLabelPair extends React.Component<GBLabelPairProps> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      status:""
    };
  }
  
  render() {
    if(this.props.isloading){
      return (
      <div className={["label-pair-container", this.props.className, "loading", (this.props.error ? "error" : "")].join(' ')}>
          <label className={"label"}>{GBText(this.props.description)}</label>
          <div className={"text-row"}>
            {this.props.children}
          </div>
      </div>)
    }

    return (
      <div className={["label-pair-container" , this.props.className, (this.props.error ? "error" : "")].join(' ')}>

          <label htmlFor={this.props.forId} className={"label"}>{GBText(this.props.description)}</label>
          <div className={"text-row"}>
            {this.props.children}
          </div>
      </div>
    );
  }
}

