import { Translate } from "./Language";

export default class Generator {
    GetHash(): string {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
}
export const GetHash = (): string => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
declare global {
    interface window { screen: any }
    // interface screen {width: any, height: any, availWidth: any}
}
export const PopupCenter = ({ url = undefined, title, w, h }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    let newWindow = undefined;
    if (url !== undefined) {

        newWindow = window.open(url, title,
            `
            scrollbars=yes,
            width=${w / systemZoom}, 
            height=${h / systemZoom}, 
            top=${top}, 
            left=${left}
            `
        )
    } else {
        newWindow = window.open("", title,
            `scrollbars=yes,
            width=${w / systemZoom}, 
            height=${h / systemZoom}, 
            top=${top}, 
            left=${left}`
        );
        newWindow.document.body.innerHTML = "<html><head></head><body>" + Translate("wait_for_redirect", "Wait for redirect") + "</body></html>";
    }
    return newWindow;
    // if (window.focus) newWindow.focus();
}

export const CreateMemberAddressString = (input: { roadName: string, roadNumber: string, houseFloor: string, houseLetter: string, houseSide: string }): string => {
    let string = ""
    if (input.roadName !== null) {
        string += input.roadName
    }
    if (input.roadNumber !== null) {
        string += " " + input.roadNumber
    }
    if (input.houseLetter !== null && input.houseLetter !== "") {
        string += "" + input.houseLetter
        if (input.houseFloor !== null && input.houseFloor !== "") {
            string += ", " + input.houseFloor
            if (input.houseSide !== null && input.houseSide !== "") {
                string += "." + input.houseSide + "."
            }
        } else {
            if (input.houseSide !== null && input.houseSide !== "") {
                string += ", " + input.houseSide + "."
            }
        }


    } else {
        if (input.houseFloor !== null && input.houseFloor !== "") {
            string += ", " + input.houseFloor
            if (input.houseSide !== null && input.houseSide !== "") {
                string += "." + input.houseSide + "."
            }
        } else {
            if (input.houseSide !== null && input.houseSide !== "") {
                string += ", " + input.houseSide + "."
            }
        }
    }

    return string;
}

// silkeborgvej 12A, 1.th.