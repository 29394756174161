import GBGhostButton, { Mode } from 'components/basic/GhostButton/GBGhostButton';
import { IconName } from 'components/basic/Icon/GBIcon';
import GBLabelPair from 'components/basic/LabelPair/GBLabelPair';
import Seperator from 'components/basic/Seperator/Seperator';
import { SignupContext, SignupContextConsumer } from 'Contexts/SignupContext';
import { PageIndex } from 'entitites/PageIndex';
import React, { Fragment, useContext } from 'react';
import { Translate } from 'utils/Language';
import { Link } from 'react-router-dom';
import { longDateString } from 'utils/DateHelper';
import { MemberShipPackDisplay } from 'localComponents/MemberShipPacks';
import AjaxHelper, { GetGBDomain } from 'utils/AjaxHelper';
import { CreateMemberAddressString, PopupCenter } from 'utils/Generator';
import GBCheckBoxSlider from 'components/basic/Checkbox/GBCheckBoxSlider';
import TermsAndConditionPanel from 'localComponents/TermsAndConditionPanel';
import { MessageContext, MessageContextConsumer } from 'Contexts/MessageContext';
import { GBResponseMessage, GBResponseType } from 'components/basic/ResponseHandler/GBResponse';




export interface OverviewPageProps {
  Id?: string;
  history?: any;
}

export interface OverviewPagestate {
  status: string;
  confirmedConditions: boolean;
  showConditions: boolean;
}

// const messageContext = useContext(MessageContext);
export default class OverviewPage extends React.Component<OverviewPageProps, OverviewPagestate> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      status: "",
      confirmedConditions: false,
      showConditions: false
    };

    if (this.context.state.memberShipPack == undefined || this.context.state.signupFormData == undefined) {
      this.props.history.push(PageIndex.Home);
    }
  }

  render() {
    if (this.context.state.memberShipPack == undefined || this.context.state.signupFormData == undefined) {
      return (<Fragment></Fragment>);
    }
    return (
      <Fragment>
        <SignupContextConsumer>
          {signupContext => (
            <div className={"flex-column"}>
              <div className={"flex-column"}>
                <div className={"flex-grow"}>
                  {MemberShipPackDisplay(signupContext.state.memberShipPack, 1, false, true)}
                </div>
                <Seperator></Seperator>
                <div className={"flex-grow flex-row-to-col-lg"}>

                  <GBLabelPair className={"pt-0 flex-grow"} description={Translate("player_information", "Player information")}>
                    <div className={"flex-column pl-2"}>
                      <label> {signupContext.state.signupFormData.FirstName + " " + signupContext.state.signupFormData.LastName}</label>
                      <label> {longDateString(signupContext.state.signupFormData.Birthdate)}</label>
                      <label> {(signupContext.state.signupFormData.Gender === "M" ? Translate("male", "Male") : Translate("female", "Female"))}</label>
                      <label> {signupContext.state.signupFormData.Nationality}</label>
                    </div>
                  </GBLabelPair>
                  <GBLabelPair className={"flex-grow"} description={Translate("player_address", "Player address")}>
                    <div className={"flex-column pl-2"}>
                      <label> {CreateMemberAddressString({
                        roadName: signupContext.state.signupFormData.RoadName,
                        roadNumber: signupContext.state.signupFormData.RoadNumber,
                        houseLetter: signupContext.state.signupFormData.HouseLetter,
                        houseFloor: signupContext.state.signupFormData.Floor,
                        houseSide: signupContext.state.signupFormData.FloorSite
                      })}</label>
                      <label> {signupContext.state.signupFormData.ZipCode + " " + signupContext.state.signupFormData.City}</label>
                      <label> {signupContext.state.signupFormData.Country}</label>
                    </div>
                  </GBLabelPair>
                </div>
              </div>
              <div className={"flex-row mt-2 size-less flex-center-vertical"}>
                <GBCheckBoxSlider isChecked={this.state.confirmedConditions} onChange={() => { this.setState({ confirmedConditions: !this.state.confirmedConditions }) }}></GBCheckBoxSlider>
                <div className={"flex-row"}>{Translate("confirm", "Confirm")} <div className={"add-link-style pr-1 pl-1"} onClick={() => { this.setState({ showConditions: true }) }}>{Translate("terms_and_conditions", "terms and conditions")}</div> {Translate("from_the_club_before_you_continue", "from the club before you continue")}</div>
              </div>
              {this.state.showConditions &&
                <TermsAndConditionPanel toggleFunction={() => this.setState({ showConditions: false })} content={signupContext.state.memberShipPack.internetPaymentTerms} open={true} ></TermsAndConditionPanel>
              }
              <Seperator></Seperator>
              <div className={" flex-col-to-row-lg flex-center gms-receipt-buttons"}>
                <Link className={"gms-button gms-first"} to={PageIndex.Home}>
                  <GBGhostButton className={"w-100 submit-very-important"} icon={IconName.arrowPrevious} highlight={false} text={Translate("fortryd", "Fortryd")} onClick={() => { }}></GBGhostButton>
                </Link>
                <div className={"gms-button gms-last"}>
                  <MessageContextConsumer>
                    {MessageContext => (

                      <GBGhostButton className={"w-100 submit-very-important"} isDisabled={!this.state.confirmedConditions} mode={Mode.accept} icon={IconName.creditcard} highlight={this.state.confirmedConditions} text={Translate("submit_and_pay", "Submit and pay")} onClick={() => { this.onSubmit(MessageContext) }}></GBGhostButton>
                    )}
                  </MessageContextConsumer>
                </div>
              </div>
            </div>
          )}
        </SignupContextConsumer>
      </Fragment>
    );
  }
  //   getmemberAddressString(roadName, roadNumber, houseFloor, houseLetter, houseSide) {
  //     let string = ""
  //     if (roadName !== null) {
  //         string += roadName
  //     }
  //     if (roadNumber !== null) {
  //         string += " " + roadNumber
  //     }
  //     if (houseLetter !== null && houseLetter !== "") {
  //         string += "" + houseLetter
  //     }
  //     if (houseFloor !== null && houseFloor !== "") {
  //         string += ", " + houseFloor
  //     }
  //     if (houseSide !== null && houseSide !== "") {
  //         string += "." + houseSide + "."
  //     }
  //     return string;
  // }
  onSubmit(messageContext) {
    let window = PopupCenter({ url: undefined, title: "Payment", w: "600", h: "600" });
    new AjaxHelper().simplePost("/public/FormHandler", { userdata: this.context.state.signupFormData, membershipPack: this.context.state.memberShipPack }, ((e) => {
      if (e.data.success) {

        if (e.data.data != null) {
          localStorage.setItem("tranactionGuid", JSON.stringify({ guid: e.data.data }));
          window.location.href = GetGBDomain() + '/site/members/memberlicense/internetpayment/payment_init.asp?GUID=' + e.data.data;
          // let test = window.open();
          // test.
          // test.location.href = GetGBDomain()+'/site/members/memberlicense/internetpayment/payment_init.asp?GUID=' + e.data.data;
          //  window.open(GetGBDomain()+'/site/members/memberlicense/internetpayment/payment_init.asp?GUID=' + e.data.data,"_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
          //  alert(Translate("redirect_to_new_payment_window","Redirect to new payment window"))
          this.props.history.push(PageIndex.waitingPage);

        }
      } else {
        window.close();
        this.props.history.push(PageIndex.Userdata);
        messageContext.addMessage(new GBResponseMessage(GBResponseType.error, e.data.message))
      }

    }))
  }

}

OverviewPage.contextType = SignupContext;