import React, { Fragment } from 'react';
import NullCheck from '../../../utils/NullCheck';
import { CustomAttribute } from '../../../entitites/CustomAttribute';
import GBIcon, { IconName } from '../Icon/GBIcon';
import GBSpinner, { GBSpinnerSize } from '../Spinner/GBSpinner';
import { IconClass } from '../Button/GBButton';

export interface GhostButtonProps {
  id?: string;
  text?: string;
  icon?: IconName;
  title?: string;
  onClick?: Function;
  className?: string;
  href?: string;
  isDisabled?: boolean;
  tabIndex?: number;
  customAttributes?: CustomAttribute[];
  btnType?: BtnType;
  mode?: Mode;
  highlight?: boolean;
  isLoading?:boolean;
  outLined?:boolean;
}
export enum BtnType {
  button,
  anchor
}

export enum Mode{
  default =  0,
  cancel = 1,
  accept = 2,
  active = 4,
  iconOnly = 8,
  iconOnlySmall = 16
}

export interface GhostButtonstate {
  status: string;
}

export default class GBGhostButton extends React.Component<GhostButtonProps, GhostButtonstate> {
  constructor(props, context) {
    super(props, context);
    this.state = {

      status: ""
    };
    this.buttonClick = this.buttonClick.bind(this);
  }

  buttonClick(e){
    e.stopPropagation()
    if(this.props.onClick != undefined){
      this.props.onClick(e);
    } 
  }

  render() {
    let Check = new NullCheck();
    let IsButton = this.props.btnType === BtnType.button || this.props.btnType === null || this.props.btnType === undefined;
    let Attributes = {};
    if(this.props.customAttributes !== undefined && this.props.customAttributes !== null){
      this.props.customAttributes.forEach(e => {
        Attributes[e.AttributeName] = e.Value;
      })
    }
    let isDisabled: boolean = this.props.isDisabled || this.props.isLoading ? true : false;
    return (
      <Fragment>
      { IsButton ? (
        <button
          onClick={(e)=>this.buttonClick(e)}
          id={Check.StringHasValue(this.props.id) ? this.props.id : ""}
          className={this.getClasses() + (this.props.isLoading ? " isLoading": "")+ (this.props.outLined || this.props.outLined === undefined ? " outlined": "")}
          title={Check.StringHasValue(this.props.title) ? this.props.title : Check.StringHasValue(this.props.text) ? this.props.text : ""}
          tabIndex={Check.NumberHasValue(this.props.tabIndex) ? this.props.tabIndex : null}
          disabled={isDisabled}
          {...Attributes}
        >
          {this.props.icon !== undefined &&(
          this.props.isLoading && !Check.StringHasValue(this.props.text) ? (
            <GBSpinner size={GBSpinnerSize.small}></GBSpinner>
          ):(
            <GBIcon Icon={this.props.icon}></GBIcon>
          )
          )
          }
          {Check.StringHasValue(this.props.text) &&(
          this.props.isLoading ? (
            <span className={["text", "text-loading"].join(' ')}>{this.props.text}<span className={"dot1"}>.</span><span className={"dot2"}>.</span><span className={"dot3"}>.</span></span>
          ):(
            <span className={"text"}>{this.props.text}</span>
          ))
          }
          {(this.props.icon === undefined && !Check.StringHasValue(this.props.text)) && 
            this.props.children
          }
        </button>
      ):(
        <a
          id={Check.StringHasValue(this.props.id) ? this.props.id : ""}
          className={this.getClasses() + (this.props.outLined  || this.props.outLined === undefined ? " outlined": "")}
          title={Check.StringHasValue(this.props.title) ? this.props.title : Check.StringHasValue(this.props.text) ? this.props.text : ""}
          tabIndex={Check.NumberHasValue(this.props.tabIndex) ? this.props.tabIndex : null}
          href={Check.StringHasValue(this.props.href) ? this.props.href : ""}
          {...Attributes}
        >
          {this.props.icon !== undefined &&
            <GBIcon Icon={this.props.icon}></GBIcon>
          }
          {Check.StringHasValue(this.props.text) &&
            <span className={"text"}>{this.props.text}</span>
          }
        </a>
      )}
      </Fragment>
    );
  }
  getClasses(): string{
    let Check = new NullCheck();
    let classes = ["ghost-btn"];
    switch (this.props.mode) {
      case Mode.default || 0:
        break;
      case Mode.accept || 2:
        classes.push("accept");
        break;
      case Mode.cancel || 1:
        classes.push("cancel");
        break;
      case Mode.active || 4:
        classes.push("active");
        break;
      case Mode.iconOnly || 8:
        classes.push("icon-only");
        break;
      case Mode.iconOnly & Mode.cancel || 9:
        classes.push("icon-only");
        classes.push("cancel");
        break;
      case Mode.iconOnly & Mode.accept || 10:
        classes.push("icon-only");
        classes.push("accept");
        break;
      case Mode.iconOnly & Mode.active || 12:
        classes.push("icon-only");
        classes.push("active");
        break;
      case Mode.iconOnlySmall || 16:
        classes.push("icon-only-small");
        break;
      case Mode.iconOnlySmall & Mode.cancel || 17:
        classes.push("icon-only-small");
        classes.push("cancel");
        break;
      case Mode.iconOnlySmall & Mode.accept || 18:
        classes.push("icon-only-small");
        classes.push("accept");
        break;
      case Mode.iconOnlySmall & Mode.active || 20:
        classes.push("icon-only-small");
        classes.push("active");
        break;
      default:
        break;
    }
    if(this.props.highlight){
        classes.push("highlight");
    }
    if(Check.HasValue(this.props.className)){
      classes.push(this.props.className);
    }
    return classes.join(' ');
  }
}

