import React, { Fragment } from 'react';



export interface SeperatorProps {
  Id?: string;
  toLabelPair?: boolean;
 }

export interface Seperatorstate {
  status: string;
}

export default class Seperator extends React.Component<SeperatorProps, Seperatorstate> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      status:""
    };
  }
  
  render() {

    return (
      <div className={"gms-seperator" + (this.props.toLabelPair ? " toLabelPair" : "")}></div>
    );
  }

}

