import { SignupFormData } from "entitites/SignupFormData";
import { MemberShipPack } from "localComponents/MemberShipPacks";
import React from "react";


interface ISignupContextState {
    memberShipPack: MemberShipPack;
    signupFormData: SignupFormData;
}
interface ISignupContext {
    state: ISignupContextState,
    setMemberShipPack: Function,
    setSignupFormData: Function
}
class SignupContextProvider extends React.Component<{}, ISignupContextState> implements ISignupContext {
    constructor(props) {
        super(props);
        this.state = {
            memberShipPack: undefined,
            signupFormData: undefined
        }
        this.setMemberShipPack = this.setMemberShipPack.bind(this);
        this.setSignupFormData = this.setSignupFormData.bind(this);
    }
    setMemberShipPack(pack) {
        this.setState({memberShipPack: pack})
    }
    setSignupFormData(formData) {
        this.setState({signupFormData: formData})
    }

    render() {
        const { children } = this.props;
        return (
            <SignupContext.Provider
                value={{
                    state: this.state,
                    setSignupFormData: this.setSignupFormData,
                    setMemberShipPack: this.setMemberShipPack
                }}
            >
                {children}
            </SignupContext.Provider>
        );
    }

}


const SignupContext = React.createContext<ISignupContext>({
    setMemberShipPack: undefined,
    setSignupFormData: undefined,
    state: {
        memberShipPack: undefined,
        signupFormData: undefined
    }
});

const SignupContextConsumer = SignupContext.Consumer
export { SignupContext, SignupContextProvider, SignupContextConsumer };
