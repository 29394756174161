import AjaxHelper from './AjaxHelper';
import React, { Fragment } from "react";

export default class Language {
    private static instance: Language = null;
    private languageArray: Object = {};
    private constructor() {
        // this.setLanguageArray = this.setLanguageArray.bind(this);
    }

    public static get Instance(): Language{
        if(this.instance === null){
            this.instance = new Language();
        }
        return this.instance;
    }

    // public LoadLanguage(isLoading: Function) {
    //         new AjaxHelper().simpleGet("/assets/language/Language_union.admin1030.json", (e) => {
    //             Language.Instance.setLanguageArray(e.data)
    //             isLoading(false);
    //         },undefined,false)
    // }
    public LoadLanguage(isLoading: Function) {
        let language = JSON.parse(localStorage.getItem("Language"))
        return new Promise((resolve, reject) => {
            new AjaxHelper().simpleGet("/assets/language/"+process.env.REACT_APP_LANGUAGEFILENAME_BASE + this.ToLcid(language) + ".json", (e) => {
                Language.Instance.setLanguageArray(e.data)
                isLoading(false);
                resolve(null);
            }, () => {
                Language.Instance.setLanguageArray([])
                isLoading(false);
                resolve(null);
            })
        })
    }

    GetValue() {
        return this.languageArray
    }
    setLanguageArray(data) {
        this.languageArray = {};
        data.forEach(element => {
            this.languageArray[element.k] = element.v;
        });
    }
    Translate(key, value, group:string = "ML_"): string {
        let valueToReturn = undefined;
        valueToReturn = this.languageArray[group + key.toUpperCase()]
        return (valueToReturn !== undefined ? valueToReturn : "*" + value);
    }
    ToLcid(lang): string {
        switch (lang) {
            case "da":
            case "da-DK":
                return "1030"
            case "is":
                return "1039"
            case "et":
                return "1061"
            case "fi":
                return "1035"
            case "lv":
                return "1062"
            case "lt":
                return "1063"
            case "no":
            case "nb-NO":
                return "1044"
            case "sv":
                return "1053"
            case "en-US":
                return "1033"
            case "de":
                return "1031"
            default:
                return "2057";
        }
    }
   
}

export const GBText = (value: string) : JSX.Element => {
    if(value.startsWith('*')) {
        return (<span className='blue'>{value.substr(1, value.length - 1)}</span>);
        } else {
          return <Fragment>{value}</Fragment>;
        }
}

export const Translate = (key : string, value : string): string => {
    return Language.Instance.Translate(key, value);
}
