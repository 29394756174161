import React from 'react';
// import styles from './GBCheckBoxSlider.module.scss';
import { CustomAttribute } from 'entitites/CustomAttribute';
import GBIcon, { IconName } from '../Icon/GBIcon';
import Generator from 'utils/Generator';

export interface GBCheckBoxSliderProps {
  id?: string;
  label?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?: Function;
}

export interface GBCheckBoxSliderstate {
}

export default class GBCheckBoxSlider extends React.Component<GBCheckBoxSliderProps, GBCheckBoxSliderstate> {
  elementId = new Generator().GetHash();
  testString: string = "";
  constructor(props, context) {
    super(props, context);

  }


  render() {
    let attr: CustomAttribute[] = [];
    if (this.props.isDisabled) { attr["disabled"] = true }
    if (this.props.onChange !== undefined) {
      attr["onChange"] = this.props.onChange
    } else {
      attr["onChange"] = () => { }
    }
    return (
      <div className="gms-custom-slider custom-control custom-switch" >
        <input type="checkbox" checked={this.props.isChecked} {...attr} className="custom-control-input focus-none" id={this.elementId} />
        <label className="custom-control-label"  htmlFor={this.elementId}></label>
      </div>
    );
  }

}

