import axios from 'axios';
import Language from './Language';
declare global {
  interface Window { GMS: any; }
}

// const GBDomain = window.GMS.GBDomain
export const GetGBDomain = () => {
  try {
    return window.GMS.gbDomain;
  } catch {
    return "https://test.golfbox.dk"
  }
}
export default class AjaxHelper {
  private Authconfig = {
    headers: {
      "crossDomain": true,
      'Authorization': 'Bearer ' + this.getToken(),
      'Language': Language.Instance.ToLcid(JSON.parse(localStorage.getItem("Language")))
    },
  }

  private noAuthconfig = {
    headers: {
      "crossDomain": true,
      'Language': Language.Instance.ToLcid(JSON.parse(localStorage.getItem("Language")))
    },
  }

  private getToken() {
    let Jsontoken = localStorage.getItem('Token');
    let token = JSON.parse(Jsontoken);
    if (token)
      return token;
  }

  public getHost() {
    try {
      return window.GMS.gbHost;
    } catch (e) {
      return "https://" + process.env.REACT_APP_LOCALHOST;
      //return "http://localhost:59581";
    }
  }

  simplePost(Url: string, Data: any, Success: Function = undefined, Error: Function = undefined, useAuth: boolean = true) {
    let config;
    if (useAuth) {
      config = this.Authconfig
    } else {
      config = this.noAuthconfig
    }
    axios.post(this.getHost() + Url, Data, config)
      .then(response => {
        if (Success !== undefined) {
          Success(response)
        } else {
          if (response.status === 403) {
            currentAuth.authenticate(false)
          }
          console.log(response)
        }
      })
      .catch(e => {
        if (Error !== undefined) {
          Error(e);
        } else {
          console.log(e)
        }
      })
  }
  simpleGet(Url: string, Success?: Function, Error?: Function, useAuth: boolean = true) {
    let config;
    if (useAuth) {
      config = this.Authconfig
    } else {
      config = this.noAuthconfig
    }
    axios.get(this.getHost() + Url, config)
      .then(function (response) {
        // handle success
        if (Success !== undefined) {
          Success(response)
        } else {
          if (response.status === 403) {
            currentAuth.authenticate(false)
          }
          console.log(response);
        }
      }).catch(e => {
        if (Error !== undefined) {
          Error(e);
        } else {
          console.log(e)
        }

      })
  }
  redirectToExternal(Url: string) {
    window.location.href = Url;
  }
  async checkAuth() {
    let config;
    config = this.Authconfig
    await axios.get(this.getHost() + "/API/User?handler=authorise", config)
      .then(function (response) {
        currentAuth.authenticate(true)
      }).catch(e => { currentAuth.authenticate(false) })
  }

}
export const currentAuth = {
  isAuthenticated: false,
  authenticate(val) {
    currentAuth.isAuthenticated = val;
  },
  signout() {
    currentAuth.isAuthenticated = false;
  }
};
export const getGBDomain = () => {
  try {
    return window.GMS.gbDomain;
  } catch (e) {
    return "https://www.golfbox.dk"
  }
}

export const getGBDomainAlternate = () => {
  try {
    return window.GMS.gbDomainAlternate;
  } catch (e) {
    return "https://www.golfbox.no"

  }
}