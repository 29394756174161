
import React, { useEffect, useState } from "react";

const GBEaseIn = ({styles, show, children }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) {
      setRender(false);}
  };
  
  return (
    shouldRender && (
       <div className={styles.test}>
        <div
          //style={{ animation: `${show ? styles.easeIn : styles.easeOut} .5s`, height: `100%`}}
          className={show ? styles.easeIn : styles.easeOut}
          onAnimationEnd={onAnimationEnd}
        >
          {children}
        </div>
      </div>
    )
  );
};

export default GBEaseIn;