import React from 'react';
import keyStylesLarge from './Animations/GBLargePanel.module.scss';
import keyStylesMedium from './Animations/GBMediumPanel.module.scss';
import keyStylesSmall from './Animations/GBSmallPanel.module.scss';
import GBIcon, { IconName } from 'components/basic/Icon/GBIcon';
import { Translate } from 'utils/Language';
import GBEaseIn from 'components/animations/EaseIn/GBEaseIn';
import GBFade from 'components/animations/Fade/GBFade';


export enum PanelSize {
    Small,
    Medium,
    Large
}
export interface GBPanelProps {
    Id?: string;
    CornerClose?: boolean;
    EasyClose?: boolean;
    AlignLeft?: boolean;
    PanelSize?: PanelSize;
    Open: boolean;
    ToggleFunction: Function;
    HeaderElement?: JSX.Element;
    FooterElement?: JSX.Element;
    className?: string;
}

export interface GBPanelState {
    status: string;
}

export default class GBPanel extends React.Component<GBPanelProps, GBPanelState> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            status: ""
        };
        this.getKeyStyles = this.getKeyStyles.bind(this);
        this.getSize = this.getSize.bind(this);
    }

    render() {
        let show = this.props.Open;
        return (
            <div className={["gb-panel", (this.props.Open ? "active" : "")].join(' ')}><GBFade show={show}>

                <div className={["overlay", (this.props.EasyClose ? "easy-close" : "")].join(' ')} onClick={() => this.props.ToggleFunction(false)}>
                </div>
            </GBFade>
                <div className={"panel-offset"}>
                    <GBEaseIn styles={this.getKeyStyles()} show={show}>
                        <div className={["panel-container", this.props.className, this.getSize(), (this.props.AlignLeft ? "align-left" : "")].join(' ')}>
                            {this.props.CornerClose &&
                                <div className={"header-bar"}>
                                    {this.props.HeaderElement !== undefined &&
                                        <div className={"head-element"}>
                                            {this.props.HeaderElement}
                                        </div>
                                    }
                                    <div className={"easy-close-row"} onClick={() => this.props.ToggleFunction(false)}>
                                        <GBIcon Icon={IconName.cancel}></GBIcon>
                                        <span>{Translate("close", "Close")}</span>
                                    </div>
                                </div>
                            }
                            <div className={"content"}>
                                {this.props.children}
                            </div>

                            {this.props.FooterElement !== undefined &&
                                <div className={"border-top"}>
                                    {this.props.FooterElement}
                                </div>
                            }
                        </div>
                    </GBEaseIn>
                </div>

            </div>
        );
    }
    //   easyClose(){
    //       console.log("easyclose")
    //     this.props.ToggleFunction(this,false)
    //   }
    private getSize(): string {
        switch (this.props.PanelSize) {
            case PanelSize.Small:
            default:
                return "small";
            case PanelSize.Medium:
                return "medium";
            case PanelSize.Large:
                return "large";
        }
    }
    private getKeyStyles() {
        switch (this.props.PanelSize) {
            case PanelSize.Small:
            default:
                return { easeIn: "small-ease-in", easeOut: "small-ease-out" };
            case PanelSize.Medium:
                return { easeIn: "medium-ease-in", easeOut: "medium-ease-out" };
            case PanelSize.Large:
                return { easeIn: "large-ease-in", easeOut: "large-ease-out" };
        }
    }
}

