import GBGhostButton from 'components/basic/GhostButton/GBGhostButton';
import { PageIndex } from 'entitites/PageIndex';
import React, { Fragment } from 'react';
import { Translate } from 'utils/Language';
import { Link } from 'react-router-dom';
import Seperator from 'components/basic/Seperator/Seperator';
import AjaxHelper, { getGBDomain, getGBDomainAlternate } from 'utils/AjaxHelper';
import { CreateMemberAddressString } from 'utils/Generator';
import { MessageContextConsumer } from 'Contexts/MessageContext';
import { GBResponseMessage, GBResponseType } from 'components/basic/ResponseHandler/GBResponse';



export interface ReceiptProps {
    Id?: string;
    location?: any;
    history?: any;
}

export interface Receiptstate {
    status: string;

}

export default class Receipt extends React.Component<ReceiptProps, Receiptstate> {
    locationState = null;
    constructor(props, context) {
        super(props, context);
        let locationState =
            this.state = {
                status: ""
            };
        this.locationState = this.props.location.state;
        if (this.locationState === null || this.locationState === undefined) {
            this.props.history.push(PageIndex.Home);
        }
        // localStorage.removeItem("tranactionGuid")
        this.toGolfBox = this.toGolfBox.bind(this);
    }

    render() {
        if (this.locationState === null || this.locationState === undefined) {
            return (<div></div>)
        }
        return (
            <Fragment>

                <div className={"flex-center flex-column gms-receipt-header"}>
                    <span className={"gms-receipt-Title"}>{Translate("receipt", "Receipt")}</span>
                    <div>
                        {/* <span>{Translate("receipt", "Receipt")}</span> */}
                        <span><b>{Translate("order_number", "Order number") + ": " + this.locationState.order.orderNumber}</b></span>
                    </div>

                </div>
                <Seperator></Seperator>
                <div className={"gms-receipt-info flex-col-to-row-lg"}>
                    <div className={"w-100 w-50-lg flex-column"}>
                        <span className={"gms-header"}>{Translate("selling-part", "Selling part")}</span>
                        <span >{this.locationState.club.name}</span>
                        <span >{this.locationState.club.address}</span>
                        <span >{this.locationState.club.zipCode + " " + this.locationState.club.city}</span>
                        <br />
                        <span >{this.locationState.club.phone}</span>
                        <span >{this.locationState.club.email}</span>
                    </div>
                    <div className={"w-100 w-50-lg flex-column gms-right"}>
                        <span className={"gms-header"}>{Translate("customer_information", "Customer information")}</span>
                        <span >{this.locationState.member.fullName}</span>
                        <span >{CreateMemberAddressString({
                            roadName: this.locationState.member.address.roadName,
                            roadNumber: this.locationState.member.address.roadNumber,
                            houseLetter: this.locationState.member.address.houseLetter,
                            houseFloor: this.locationState.member.address.houseFloor,
                            houseSide: this.locationState.member.address.houseSide
                        })}</span>
                        <span >{this.getMemberZipString()}</span>
                        <br />
                        <span >{this.locationState.member.mobilePhone}</span>
                        <span >{this.locationState.member.email}</span>
                    </div>
                </div>
                <Seperator></Seperator>
                <div className={"flex-column gms-receipt-orderlines"}>
                    {this.locationState.order.orderLines.map((e, index) => (
                        <Fragment>
                            { e.quantity > 0 &&
                                <div key={index} className={"flex-row gms-receipt-order"}>
                                    <span className={"flex-grow"}>{e.text}</span>
                                    <span>{e.quantity + " x " + e.price.toLocaleString(undefined, { minimumFractionDigits: 2 }) + " " + this.locationState.order.currency + "."}</span>
                                </div>
                            }
                        </Fragment>
                    ))}
                    <div className={"flex-row gms-receipt-order"}>
                        <span className={"flex-grow"}>{Translate("total", "Total")}</span>
                        <span>{this.getSumOfOrderlines() + " " + this.locationState.order.currency + "."}</span>
                    </div>
                </div>
                <div className={"w-100 flex-center-horisontal gms-sent-too"}>
                    <span><i>{Translate("this_receipt_has_also_been_sent_to_your_email", "This receipt has also been sent to your email")}</i></span>
                </div>
                <Seperator></Seperator>
                <div className={" flex-col-to-row-lg flex-center gms-receipt-buttons"}>
                    <div className={"gms-button gms-last"}>
                    <MessageContextConsumer>
                    {MessageContext => (

                    
                        <GBGhostButton onClick={() => {
                            this.toGolfBox(MessageContext)
                        }} className={"w-100"} highlight={true} text={Translate("to_GolfBox", "To GolfBox")}></GBGhostButton>
                        )}
                  </MessageContextConsumer>
                    </div>
                </div>
            </Fragment>
        );
    }
    toGolfBox(messageContext) {
        new AjaxHelper().simpleGet("/API/SSO?handler=SSOToken", (e) => {
            console.log(e)
            if (e.data.success) {
                new AjaxHelper().redirectToExternal(apiUrl + "/external/internationallogin/receivesso.asp?token=" + e.data.data);
            } else {
                messageContext.addMessage(new GBResponseMessage(GBResponseType.error, e.data.message))
            }
        }, (error) => { console.log(error) }, true)
        let apiUrl = getGBDomain();
    }
    getSumOfOrderlines() {
        let sum: number = 0;
        let root = this.locationState.order.orderLines;
        root.forEach(line => {
            let lineprice: number = 0;
            lineprice += line.price;
            sum += lineprice;
        });
        return sum.toLocaleString(undefined, { minimumFractionDigits: 2 });
    }
    // getmemberAddressString() {
    //     let string = ""
    //     let root = this.locationState.member.address
    //     if (root.roadName !== null) {
    //         string += root.roadName
    //     }
    //     if (root.roadNumber !== null) {
    //         string += " " + root.roadNumber
    //     }
    //     if (root.houseLetter !== null && root.houseLetter !== "") {
    //         string += "" + root.houseLetter
    //     }
    //     if (root.houseFloor !== null && root.houseFloor !== "") {
    //         string += ", " + root.houseFloor
    //     }
    //     if (root.houseSide !== null && root.houseSide !== "") {
    //         string += "." + root.houseSide + "."
    //     }
    //     return string;
    // }
    
    getMemberZipString() {
        let string = ""
        let root = this.locationState.member.address
        if (root.zipCode !== null) {
            string += root.zipCode + " "
        }
        if (root.city !== null) {
            string += root.city
        }
        return string;
    }
}

