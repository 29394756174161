import MemberShipPacks from 'localComponents/MemberShipPacks';
import React, { Fragment } from 'react';

export interface IndexProps {
  Id?: string;
  history?: any;
}

export interface Indexstate {

}

export default class Index extends React.Component<IndexProps, Indexstate> {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <Fragment>
          <MemberShipPacks history={this.props.history}></MemberShipPacks>
      </Fragment >
    );
  }
}

