
export const longDateString = (currentDate: Date): string =>{
    var options = { weekday: 'long',month: 'long',  day: '2-digit', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(currentDate)
}
export const shortDateString = (currentDate: Date): string =>{
    var options = {day: '2-digit', month: 'short'};
    return new Intl.DateTimeFormat('en-US', options).format(currentDate)
}

export const timeString = (currentDate: Date): string =>{
    var options = {hour: "2-digit", minute: "2-digit" };
    return new Intl.DateTimeFormat('en-US', options).format(currentDate)
}