import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
//import './styles/PublicStyles/GB-icons.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
document.getElementById('GMS-OnlineSignup').classList.add("theme-dark");
ReactDOM.render(<App />, document.getElementById('GMS-OnlineSignup'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
