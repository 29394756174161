import React from 'react';
import GBIcon, { IconName } from '../Icon/GBIcon';
import { Translate } from 'utils/Language';

export enum GBResponseType {
  error = 0,
  ok = 1,
  warning = 2,
  attention = 4,
  info = 8
}
export class GBResponseMessage {
  type: GBResponseType;
  message: string;

  constructor(type: GBResponseType, message: string) {
    this.message = message;
    this.type = type;
  }
}

export interface GBResponseProps {
  Id?: string;
  responses: GBResponseMessage[];
  Onclose?: Function;
  isFloating?: boolean;
}

export class ResponseTypeSettings {
  icon: IconName;
  className: string;
  title: string;

  constructor(ResponseTypeSettings: ResponseTypeSettings) {
    Object.assign(this, ResponseTypeSettings)
  }
}
export default class GBResponse extends React.Component<GBResponseProps> {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    let onClose: Function = ()=>{console.error("GBResponse Onclose property not set!")};
    if(this.props.Onclose){
      onClose = this.props.Onclose;
    }
    if (this.props.isFloating) {
      return (
        <div className={"status-container"}>
          <div className={"message-rows"}>
            {this.props.responses.map((response, index) =>
              <div className={"row " + this.getType(response.type).className} key={"response-" + index}>
                <div className={"messages"}>
                  <GBIcon className={"typeIcon error"} Icon={this.getType(response.type).icon}></GBIcon>
                  <div>
                    <div className={"message-header"}>{this.getType(response.type).title}</div>
                    {response.message}
                  </div>
                </div>
                <GBIcon className={"icon"} Icon={IconName.cancel} Onclick={() => {console.log(index); onClose(index,response)}}></GBIcon>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className={"response-container"}>
          {this.props.responses.map((response, index) =>
            <div className={[this.getType(response.type).className, "box", "mb-3"].join(' ')} key={Math.random()}>
              <GBIcon className={"message-icon mr-1"} Icon={this.getType(response.type).icon}  Onclick={() => {console.log(index); onClose(index,response)}}></GBIcon>
              <span className={"message-text"}>{response.message}</span>
            </div>
          )}
        </div>
      );
    }
  }

  getType(type: GBResponseType): ResponseTypeSettings {
    let returnType: ResponseTypeSettings = new ResponseTypeSettings({ icon: IconName.statusCancel, className: "error-box", title: "" });

    switch (type) {
      case GBResponseType.error:
      default:
        returnType.icon = IconName.statusCancel
        returnType.className = "error"
        returnType.title = Translate("Error","Error");
        break;
      case GBResponseType.ok:
        returnType.icon = IconName.statusAccept
        returnType.className = "success"
        returnType.title = Translate("Success","Success");
        break;
      case GBResponseType.warning:
        returnType.icon = IconName.statusWarning
        returnType.className = "warning"
        returnType.title = Translate("Warning","Warning");
        break;
      case GBResponseType.attention:
        returnType.icon = IconName.statusAttention
        returnType.className = "attention"
        returnType.title = Translate("Attention","Attention");
        break;
      case GBResponseType.info:
        returnType.icon = IconName.statusInfo
        returnType.className = "info"
        returnType.title = Translate("Info","Info");
        break;
    }
    return returnType;
  }
}