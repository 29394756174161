import React, { Fragment } from "react";

import { Route } from 'react-router-dom'
import { PageIndex } from "entitites/PageIndex";
import Index from "pages/Index";
import Userdata from "pages/Userdata";
import Receipt from "pages/Receipt";
import WaitingPage from "pages/WaitingPage";
import { SignupContextProvider } from "Contexts/SignupContext";
import OverviewPage from "pages/OverviewPage";

export const AllRoutes = () => {
  return (
    <Fragment>
      <SignupContextProvider>
        <Route exact path={PageIndex.Home} component={Index} redirectPage={PageIndex.Home} />
        <Route path={PageIndex.Userdata} component={Userdata} />
        <Route path={PageIndex.Overview} component={OverviewPage} />
        <Route path={PageIndex.receipt} component={Receipt} />
        <Route path={PageIndex.waitingPage} component={WaitingPage} />
      </SignupContextProvider>
    </Fragment>
  )
}
export const PrivateRoute = ({ component: Component, redirectPage, ...rest }) => (
  <Route {...rest} render={(props) => (
    currentAuth.isAuthenticated === true
      ? <Component {...props} /> : <Component {...props} />
    // : <Redirect to={{pathname: PageIndex.Login, state:{redirectPage: redirectPage}}}  />
  )} />
)
export const currentAuth = {
  isAuthenticated: false,
  authenticate(val) {
    currentAuth.isAuthenticated = val;
  },
  signout() {
    currentAuth.isAuthenticated = false;
  }
};

