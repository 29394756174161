import React, { Fragment } from 'react';
import NullCheck from 'utils/NullCheck';
import Generator from 'utils/Generator';
import InputAjaxHelper from './InputAjaxhelper';
import { CustomAttribute } from 'entitites/CustomAttribute';
import GBIcon, { IconName } from '../Icon/GBIcon';

export enum InputTheme {
    Default,
    Date,
    Search
}

export interface GB_inputProps {
    id?: string;
    url?: string;
    name?: string;
    inputIcon?: IconName;
    customAttributes?: CustomAttribute[];
    containerId?: string;
    containerClassName?: string;
    onKeyup?: Function;
    onKeyPress?: Function;
    onChange?: Function;
    onblur?: Function;
    iconId?: string;
    value?: string;
    defaultValue?: string;
    placeholder?: string;
    className?: string;
    isDisabled?: boolean;
    required?: boolean;
    validation?: string;
    errorMessage?: string;
    tabIndex?: number;
    bindValue?: string;
    min?: number;
    max?: number;
    isLarge?:boolean;
}

export interface GB_inputstate {
    status: string;
}

export default class GBInput extends React.Component<GB_inputProps, GB_inputstate> {
    testString: string = "";
    inputID: string = new Generator().GetHash();
    constructor(props, context) {
        super(props, context);
        this.state = {
            status: ""
        };
    }

    render() {
        let Check = new NullCheck();
        let inputAttributes = {};
        let IconAttributes = {};
        let formId = new Generator().GetHash();
        let isAjaxInput = Check.StringHasValue(this.props.url);

        if (Check.StringHasValue(this.props.id)) { inputAttributes["id"] = this.props.id };
        if (Check.StringHasValue(this.props.name)) { inputAttributes["name"] = this.props.name };
        if (Check.StringHasValue(this.props.placeholder)) { inputAttributes["placeholder"] = this.props.placeholder };
        if (this.props.required) { inputAttributes["required"] = "required" };
        if (Check.FunctionHasValue(this.props.onKeyup)) { inputAttributes["onKeyUp"] = this.props.onKeyup };
        if (Check.FunctionHasValue(this.props.onChange)) { inputAttributes["onChange"] = this.props.onChange };
        if (Check.FunctionHasValue(this.props.onblur)) { inputAttributes["onBlur"] = this.props.onblur };
        if (Check.NumberHasValue(this.props.tabIndex)) { inputAttributes["tabindex"] = this.props.tabIndex };
        if (Check.FunctionHasValue(this.props.onKeyPress)) { inputAttributes["onKeyPress"] = this.props.onKeyPress };
        if (Check.StringHasValue(this.props.defaultValue)) { inputAttributes["defaultValue"] = this.props.defaultValue };
        if (Check.StringHasValue(this.props.value)) { inputAttributes["value"] = this.props.value };
        if (this.props.isDisabled) { inputAttributes["disabled"] = "disabled" };
        if (this.props.customAttributes !== undefined && this.props.customAttributes !== null) {
            this.props.customAttributes.forEach(e => {
                inputAttributes[e.AttributeName] = e.Value;
            })
        }

        if (Check.StringHasValue(this.props.iconId)) { IconAttributes["id"] = this.props.iconId };
        return (
            <div className={ ["fast-input-container", 
            (Check.StringHasValue(this.props.containerClassName) ? this.props.containerClassName : ""), 
            (this.props.errorMessage ? "error": "")
            ].join(" ")}>
                {isAjaxInput ? (
                    <Fragment>
                        <form id={formId}>
                            <input {...inputAttributes} onBlur={new InputAjaxHelper().simpleSubmit.bind(formId, this.props.url,
                                Check.StringHasValue(this.props.containerId) ? this.props.containerId : "",
                                Check.StringHasValue(this.props.validation) ? this.props.validation : "")}
                                
                                id={(Check.StringHasValue(this.props.id) ? this.props.id : this.inputID)}
                                className={["fast-input",(this.props.isLarge ? "large" : ""), this.props.className].join(' ')}
                            ></input>
                            {/* {this.props.InputIcon !== IconClass.None &&
                                 TODO <div className={"gb-icon " + this.GetInputIcon()} {...IconAttributes} onClick={()=>this.targetSibling()}></div>
                            } */}
                            {Check.HasValue(this.props.inputIcon) &&
                                <GBIcon className={"gb-icon"} Icon={this.props.inputIcon} Onclick={()=>this.targetSibling()}></GBIcon>
                            }
                        </form>
                    </Fragment>
                ) : (
                        <Fragment>
                            <input {...inputAttributes}
                               
                                id={(Check.StringHasValue(this.props.id) ? this.props.id : this.inputID)}
                                className={["fast-input",(this.props.isLarge ? "large" : ""), this.props.className].join(' ')}
                            ></input>
                            {new NullCheck().StringHasValue(this.props.errorMessage) &&
                                <label>{this.props.errorMessage}</label>
                            }
                            {/* {this.props.InputIcon !== IconClass.None &&
                               TODO <div className={"gb-icon " + this.GetInputIcon()} {...IconAttributes} onClick={()=>this.targetSibling()}></div>
                            } */}
                            {Check.HasValue(this.props.inputIcon) &&
                                <GBIcon className={"gb-icon"} Icon={this.props.inputIcon} Onclick={()=>this.targetSibling()}></GBIcon>
                            }
                        </Fragment>
                    )}
            </div>
        );
    }
    
    private targetSibling() {
        document.getElementById((new NullCheck().StringHasValue(this.props.id) ? this.props.id : this.inputID)).focus();
    }

}

