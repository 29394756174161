import { IsBooleanString, IsDate, IsEmail, IsNotEmpty, Length, Matches, MaxDate, Min, MinLength, ValidateIf } from "class-validator";
import { Translate } from "utils/Language";
import { DependentValue, Match } from "utils/Validation/CustomValidators";

export class SignupFormData {
    @IsNotEmpty({ message: ()=>Translate("required_field", "Required field") })
    FirstName: string;
    @IsNotEmpty({ message: ()=>Translate("required_field", "Required field") })
    LastName: string;
    @IsEmail(undefined, { message: ()=>Translate("Email_must_be_valid", "Email must be valid") })
    @IsNotEmpty({ message: ()=>Translate("required_field", "Required field") })
    Email: string;
    @IsNotEmpty({ message: ()=>Translate("required_field", "Required field")  })
    MobileNumber: string;
    @IsNotEmpty({ message: ()=>Translate("required_field", "Required field") })
    Nationality: string;
    @IsNotEmpty({ message: ()=>Translate("required_field", "Required field") })
    Gender: string;
    @IsNotEmpty({ message: ()=>Translate("required_field", "Required field") })
    @IsDate()
    @MaxDate(new Date, {message: ()=>Translate("the_date_cannot_be_in_the_future","The date cannot be in the future")})
    Birthdate: Date;
    
    @IsNotEmpty({ message: ()=>Translate("required_field", "Required field") })
    RoadName: string;
    @IsNotEmpty({ message: ()=>Translate("required_field", "Required field") })
    RoadNumber: string;
    HouseLetter: string;
    Floor: string;
    FloorSite: string;
    @IsNotEmpty({ message: ()=>Translate("required_field", "Required field") })
    @DependentValue("Country")
    ZipCode: string;
    City: string;
    Country: string;
    PreviousHCP: string;
    
    constructor(init?: SignupFormData) {
        Object.assign(this, init);
    }
}
