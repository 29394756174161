import { GBResponseMessage } from "components/basic/ResponseHandler/GBResponse";
import React from "react";


interface IMessageContextState {
    MessageList: GBResponseMessage[];
}
interface IMessageContext {
    state: IMessageContextState,
    addMessage: Function,
    removeMessage: Function
}
class MessageContextProvider extends React.Component<{}, IMessageContextState> implements IMessageContext {
    constructor(props) {
        super(props);
        this.state = {
            MessageList: []
        }
        this.addMessage = this.addMessage.bind(this);
        this.removeMessage = this.removeMessage.bind(this);
    }
    addMessage(message: GBResponseMessage) {
        let list = this.state.MessageList;
        list.push(message);
        this.setState({MessageList: list})
    }
    removeMessage(index) {
        let list = this.state.MessageList;
        if (index > -1) {
            list.splice(index, 1);
          }
        
        this.setState({MessageList: list})
    }



    render() {
        const { children } = this.props;
        return (
            <MessageContext.Provider
                value={{
                    state: this.state,
                    addMessage: this.addMessage,
                    removeMessage: this.removeMessage
                }}
            >
                {children}
            </MessageContext.Provider>
        );
    }

}


const MessageContext = React.createContext<IMessageContext>({
    addMessage: undefined,
    removeMessage: undefined,
    state: {
        MessageList: []
    }
});

const MessageContextConsumer = MessageContext.Consumer
export { MessageContext, MessageContextProvider, MessageContextConsumer };
