//import { truncate } from "fs" causes an error
export default class InputAjaxHelper {

    simpleSubmit(formId: string, controllerUrl: string, spinnerTarget: string, ValidationMethod: string) {
        //TODO create call with validation using axois helper, load spinner while waiting
        console.log("TODO")

}

// if needed rewrite from jquery to ts
validateAjaxForm(formId) {
    // console.log("standard")
    // let $inputs = $('#' + formId).children().filter('input');
    // let validationStatus = false;
    // $inputs.each(function () {
    //     let type = this.type;
    //     switch (type) {
    //         case "text":
    //             validationStatus = true;
    //             break;
    //         case "email":
    //             var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    //             validationStatus = regex.test(this.value);
    //             break;
    //         case "number":
    //             validationStatus = true;
    //             break;
    //     }
    //     if (!validationStatus) {
    //         return {
    //             status: false,
    //             errorAtTarget: this
    //         }
    //     }
    // })
    // return {
    //     status: validationStatus
    // }

}

}