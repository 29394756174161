import React, { Fragment } from 'react';
import Article from './components/Layout/Article';
import { HashRouter} from 'react-router-dom'
import { createBrowserHistory } from "history";
import Language from 'utils/Language';
import { AppProvider } from 'AppContext';
import { MessageContextConsumer, MessageContextProvider } from 'Contexts/MessageContext';
import GBResponse from 'components/basic/ResponseHandler/GBResponse';

const history = createBrowserHistory();


export interface AppProps {
  Id?: string;
  CssClass?: string;
  Style?: string;
  Text?: string;
  Icon?: string;
  buttonFunction: any;

}
export interface Appstate {
  status: string;
  menuOpen: boolean;
  isLoading: boolean;
}

export default class App extends React.Component<{}, Appstate> {
  constructor(props, context) {
    super(props, context);
    if (localStorage.getItem("Language") === null) {
      localStorage.setItem("Language", JSON.stringify(navigator.language))
    }
    this.isLoading = this.isLoading.bind(this);
    this.initLoading()
    this.state = {
      status: "",
      menuOpen: false,
      isLoading: true
    };
    this.ToggleMainMenu = this.ToggleMainMenu.bind(this)
  }
  // bookingguideStart = '!function (a) { function e(e) { for (var r, t, n = e[0], o = e[1], u = e[2], l = 0, f = []; l < n.length; l++)t = n[l], Object.prototype.hasOwnProperty.call(i, t) && i[t] && f.push(i[t][0]), i[t] = 0; for (r in o) Object.prototype.hasOwnProperty.call(o, r) && (a[r] = o[r]); for (s && s(e); f.length;)f.shift()(); return c.push.apply(c, u || []), p() } function p() { for (var e, r = 0; r < c.length; r++) { for (var t = c[r], n = !0, o = 1; o < t.length; o++) { var u = t[o]; 0 !== i[u] && (n = !1) } n && (c.splice(r--, 1), e = l(l.s = t[0])) } return e } var t = {}, i = { 1: 0 }, c = []; function l(e) { if (t[e]) return t[e].exports; var r = t[e] = { i: e, l: !1, exports: {} }; return a[e].call(r.exports, r, r.exports, l), r.l = !0, r.exports } l.m = a, l.c = t, l.d = function (e, r, t) { l.o(e, r) || Object.defineProperty(e, r, { enumerable: !0, get: t }) }, l.r = function (e) { "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, { value: "Module" }), Object.defineProperty(e, "__esModule", { value: !0 }) }, l.t = function (r, e) { if (1 & e && (r = l(r)), 8 & e) return r; if (4 & e && "object" == typeof r && r && r.__esModule) return r; var t = Object.create(null); if (l.r(t), Object.defineProperty(t, "default", { enumerable: !0, value: r }), 2 & e && "string" != typeof r) for (var n in r) l.d(t, n, function (e) { return r[e] }.bind(null, n)); return t }, l.n = function (e) { var r = e && e.__esModule ? function () { return e.default } : function () { return e }; return l.d(r, "a", r), r }, l.o = function (e, r) { return Object.prototype.hasOwnProperty.call(e, r) }, l.p = "/"; var r = this["webpackJsonpgolfbox.player"] = this["webpackJsonpgolfbox.player"] || [], n = r.push.bind(r); r.push = e, r = r.slice(); for (var o = 0; o < r.length; o++)e(r[o]); var s = n; p() }([])';
  async initLoading() {
    await Promise.all([Language.Instance.LoadLanguage(() => { })]
    
    ).then(()=>{this.isLoading(false)}).catch(() => { localStorage.setItem("Language", JSON.stringify("en-GB"));this.isLoading(false)  });
  }

  isLoading(val) {
    this.setState({ isLoading: val })
  }
  render() {
    if (this.state.isLoading) {
      return (<div>isloading</div>)
    }

    return (
      <AppProvider
        value={{ Booking: undefined }}
      >
            <MessageContextProvider>
        <HashRouter history={history}>
          <Fragment>
            <MessageContextConsumer>
            {messageContext => (
              <GBResponse  Onclose={messageContext.removeMessage} responses={messageContext.state.MessageList}></GBResponse>
            )}
            </MessageContextConsumer>
            <section className="flex-row">
              <Article></Article>
            </section>
          </Fragment>
        </HashRouter >
            </MessageContextProvider>
      </AppProvider>
    );
  }


  ToggleMainMenu(val: boolean = undefined) {
    this.setState({ menuOpen: val !== undefined ? val : !this.state.menuOpen })
  }
  editUserFunction() {
    alert('hi');

  }
}



