import GBPanel from 'components/advanced/Panel/GBPanel';
import React, { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';



export interface TermsAndConditionPanelProps {
  Id?: string;
  open: boolean;
  toggleFunction: Function;
  content: string;
 }

export interface TermsAndConditionPanelstate {
  status: string;
}

export default class TermsAndConditionPanel extends React.Component<TermsAndConditionPanelProps, TermsAndConditionPanelstate> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      status:"",
    };
  }
  
  render() {

    return (
      <GBPanel CornerClose={true} ToggleFunction={this.props.toggleFunction} Open={this.props.open}>
         <textarea disabled className="h-100 w-100" >{this.props.content}</textarea>
      </GBPanel>
    );
  }

}

