import { validate } from 'class-validator';
import GBDatePicker from 'components/basic/DatePicker/GBDatePicker';
import GBDropdown, { GBDropdownElements } from 'components/basic/Dropdown/GBDropdown';
import GBGhostButton, { Mode } from 'components/basic/GhostButton/GBGhostButton';
import { IconName } from 'components/basic/Icon/GBIcon';
import GBInput from 'components/basic/Input/GBInput';
import GBLabelPair from 'components/basic/LabelPair/GBLabelPair';
import Seperator from 'components/basic/Seperator/Seperator';
import { SignupContext } from 'Contexts/SignupContext';
import { PageIndex } from 'entitites/PageIndex';
import { SignupFormData } from 'entitites/SignupFormData';
import React, { Fragment } from 'react';
import AjaxHelper from 'utils/AjaxHelper';
import { DropDownLists } from 'utils/DropDownLists';
import { Translate } from 'utils/Language';
import { ValidationHelpers } from 'utils/Validation/ValidationHelpers';
import { Link } from 'react-router-dom';
import GBCheckBoxSlider from 'components/basic/Checkbox/GBCheckBoxSlider';

export interface UserdataProps {
  Id?: string;
  history?: any;
}

export interface Userdatastate {
  PlayerData: SignupFormData,
  Nationalities: GBDropdownElements[];
  Genders: GBDropdownElements[];
  Countries: GBDropdownElements[];
  Error: { PropName: string, message: string }[];
  isLoading: boolean;
  showHCPField: boolean;
  HasZipCodes: boolean;
}
export default class Userdata extends React.Component<UserdataProps, Userdatastate> {
  // static contextType = SignupContext;
  nationalitiList = [];
  constructor(props, context) {
    super(props, context);
    let currentDate = new Date();
    currentDate.setFullYear(new Date().getFullYear() - 50)
    let playerData = new SignupFormData({
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNumber: "",
      Nationality: "",
      Gender: "",
      Birthdate: undefined,
      RoadName: "",
      RoadNumber: "",
      HouseLetter: "",
      Floor: "",
      FloorSite: "",
      ZipCode: "",
      City: "",
      Country: "",
      PreviousHCP: ""
    })
    if (this.context.state.signupFormData !== undefined) {
      playerData = this.context.state.signupFormData
    }
    this.state = {
      isLoading: true,
      PlayerData: playerData,
      Nationalities: [],
      Genders: DropDownLists.getGenderList(),
      Countries: [],
      Error: [],
      showHCPField: true,
      HasZipCodes: true
    }
   
    if (this.context.state.memberShipPack == undefined) {
      console.log("go to home")
      this.props.history.push(PageIndex.Home);
    } else {
      let _this = this;
      this.getNationalities().then(e => {
        let hasZips = true;
        if (this.context.state.signupFormData !== undefined) {
          hasZips = _this.nationalitiList.find(a => a.iso == this.context.state.signupFormData.Country ).hasZipCodes
        }
        this.setState({
          Nationalities: e.map(a => ({ ...a })),
          Countries: e.map(a => ({ ...a })),
          isLoading: false,
          HasZipCodes: hasZips
        });
      })
    }
    this.findCity = this.findCity.bind(this);

  }

  render() {
    if (this.state.isLoading) {
      return (<div>Loading</div>)
    }
    return (
      <Fragment>
        <div>


          <div className={"flex-column"}>
            <GBLabelPair className={""} description={Translate("first_name", "First name")}>
              <GBInput required={true} value={this.state.PlayerData.FirstName}
                errorMessage={ValidationHelpers.getErrorMessage("FirstName", this)}
                onblur={() => validate(this.state.PlayerData).then((e) => { ValidationHelpers.addError(e, "FirstName", this) })}
                onChange={(e) => { let temp = this.state.PlayerData; temp.FirstName = e.target.value; this.setState({ PlayerData: temp }) }}
                className={"flex-grow"} containerClassName={"flex-grow"} ></GBInput>
            </GBLabelPair>
            <GBLabelPair className={""} description={Translate("last_name", "Last name")}>
              <GBInput value={this.state.PlayerData.LastName}
                errorMessage={ValidationHelpers.getErrorMessage("LastName", this)}
                onblur={() => validate(this.state.PlayerData).then((e) => { ValidationHelpers.addError(e, "LastName", this) })}
                onChange={(e) => { let temp = this.state.PlayerData; temp.LastName = e.target.value; this.setState({ PlayerData: temp }) }}
                className={"flex-grow"} containerClassName={"flex-grow"}></GBInput>
            </GBLabelPair>
            <div className={"flex-col-to-row-lg w-100"}>

              <GBLabelPair className={" w-100 pr-lg-2 gms-left-element"} description={Translate("email", "Email")}>
                <GBInput errorMessage={ValidationHelpers.getErrorMessage("Email", this)}
                  onblur={() => validate(this.state.PlayerData).then((e) => { ValidationHelpers.addError(e, "Email", this) })}
                  value={this.state.PlayerData.Email} onChange={(e) => { let temp = this.state.PlayerData; temp.Email = e.target.value; this.setState({ PlayerData: temp }) }} className={"flex-grow"} containerClassName={"flex-grow"}></GBInput>
              </GBLabelPair>
              <GBLabelPair className={"w-100 gms-right-element"} description={Translate("mobile_number", "Mobile number")}>
                <GBInput errorMessage={ValidationHelpers.getErrorMessage("MobileNumber", this)}
                  onblur={() => validate(this.state.PlayerData).then((e) => { ValidationHelpers.addError(e, "MobileNumber", this) })}
                  value={this.state.PlayerData.MobileNumber} onChange={(e) => { let temp = this.state.PlayerData; temp.MobileNumber = e.target.value; this.setState({ PlayerData: temp }) }} className={"flex-grow"} containerClassName={"flex-grow"}></GBInput>
              </GBLabelPair>
            </div>
            <GBLabelPair className={""} description={Translate("nationality", "Nationality")}>
              <GBDropdown
                errorMessage={ValidationHelpers.getErrorMessage("Nationality", this)}
                selectionFunction={((e) => { let temp = this.state.PlayerData; temp.Nationality = e; this.setState({ PlayerData: temp }); validate(this.state.PlayerData).then((e) => { ValidationHelpers.addError(e, "Nationality", this) }) })}
                allowSearch={true}
                Elements={this.updateDropDownSelection(this.state.Nationalities, this.state.PlayerData.Nationality)}></GBDropdown>
            </GBLabelPair>
            <div className={"flex-col-to-row-lg w-100"}>
              <GBLabelPair className={" w-100 pr-lg-2 gms-left-element"} description={Translate("gender", "Gender")}>
                <GBDropdown
                  errorMessage={ValidationHelpers.getErrorMessage("Gender", this)}
                  selectionFunction={((e) => { let temp = this.state.PlayerData; temp.Gender = e; this.setState({ PlayerData: temp }); validate(this.state.PlayerData).then((e) => { ValidationHelpers.addError(e, "Gender", this) }) })}
                  Elements={this.updateDropDownSelection(this.state.Genders, this.state.PlayerData.Gender)}></GBDropdown>
              </GBLabelPair>
              <GBLabelPair className={" w-100 gms-right-element"} description={Translate("birthdate", "Birthdate")}>
                <GBDatePicker
                  errorMessage={ValidationHelpers.getErrorMessage("Birthdate", this)}
                  setDate={((e) => { let temp = this.state.PlayerData; temp.Birthdate = e; this.setState({ PlayerData: temp }); validate(this.state.PlayerData).then((e) => { ValidationHelpers.addError(e, "Birthdate", this) }) })}
                  DefaultDate={this.state.PlayerData.Birthdate}
                  ShowYearDropDown={true} ></GBDatePicker>
              </GBLabelPair>
            </div>

            <Seperator toLabelPair={true}></Seperator>

            <GBLabelPair className={""} description={Translate("road_name", "Road name")}>
              <GBInput errorMessage={ValidationHelpers.getErrorMessage("RoadName", this)}
                onblur={() => validate(this.state.PlayerData).then((e) => { ValidationHelpers.addError(e, "RoadName", this) })}
                value={this.state.PlayerData.RoadName} onChange={(e) => { let temp = this.state.PlayerData; temp.RoadName = e.target.value; this.setState({ PlayerData: temp }) }} className={"flex-grow"} containerClassName={"flex-grow"}></GBInput>
            </GBLabelPair>
            <div className={"flex-row flex-wrap-start flex-unwrap-md"}>
              <GBLabelPair className={"w-100 pr-md-1"} description={Translate("road_number", "Road number")}>
                <GBInput errorMessage={ValidationHelpers.getErrorMessage("RoadNumber", this)}
                  onblur={() => validate(this.state.PlayerData).then((e) => { ValidationHelpers.addError(e, "RoadNumber", this) })}
                  value={this.state.PlayerData.RoadNumber} onChange={(e) => { let temp = this.state.PlayerData; temp.RoadNumber = e.target.value; this.setState({ PlayerData: temp }) }} className={"flex-grow"} containerClassName={"flex-grow"}></GBInput>
              </GBLabelPair>
              <GBLabelPair className={"w-100 pr-md-1 pl-md-1"} description={Translate("house_letter", "House letter")}>
                <GBInput errorMessage={ValidationHelpers.getErrorMessage("HouseLetter", this)}
                  onblur={() => validate(this.state.PlayerData).then((e) => { ValidationHelpers.addError(e, "HouseLetter", this) })}
                  value={this.state.PlayerData.HouseLetter} onChange={(e) => { let temp = this.state.PlayerData; temp.HouseLetter = e.target.value; this.setState({ PlayerData: temp }) }} className={"flex-grow"} containerClassName={"flex-grow"}></GBInput>
              </GBLabelPair>
              <GBLabelPair className={"w-100 pr-md-1 pl-md-1"} description={Translate("floor", "Floor")}>
                <GBInput errorMessage={ValidationHelpers.getErrorMessage("Floor", this)}
                  onblur={() => validate(this.state.PlayerData).then((e) => { ValidationHelpers.addError(e, "Floor", this) })}
                  value={this.state.PlayerData.Floor} onChange={(e) => { let temp = this.state.PlayerData; temp.Floor = e.target.value; this.setState({ PlayerData: temp }) }} className={"flex-grow"} containerClassName={"flex-grow"}></GBInput>
              </GBLabelPair>
              <GBLabelPair className={"w-100 pl-md-1"} description={Translate("side", "Side")}>
                <GBInput errorMessage={ValidationHelpers.getErrorMessage("FloorSite", this)}
                  value={this.state.PlayerData.FloorSite}
                  onblur={() => validate(this.state.PlayerData).then((e) => { ValidationHelpers.addError(e, "FloorSite", this) })}
                  onChange={(e) => { let temp = this.state.PlayerData; temp.FloorSite = e.target.value; this.setState({ PlayerData: temp }) }}
                  className={"flex-grow"} containerClassName={"flex-grow"}></GBInput>
              </GBLabelPair>
            </div>
            <GBLabelPair className={""} description={Translate("country", "Country")}>
              <GBDropdown
                errorMessage={ValidationHelpers.getErrorMessage("Country", this)}
                selectionFunction={((e) => {
                  let temp = this.state.PlayerData;
                  temp.Country = e;
                  let hasZips = this.nationalitiList.find(a => a.iso == e ).hasZipCodes
                  this.setState({ PlayerData: temp, HasZipCodes: hasZips });
                  validate(this.state.PlayerData).then((e) => {
                    ValidationHelpers.addError(e, "Country", this)
                    if (this.state.PlayerData.ZipCode !== "") {
                      document.querySelector('#gms-zipInput').dispatchEvent(new Event('blur'))
                    }
                  })
                })
                }
                allowSearch={true}
                Elements={this.updateDropDownSelection(this.state.Countries, this.state.PlayerData.Country)}></GBDropdown>
            </GBLabelPair>
            <div className={"flex-col-to-row-md"}>
              <GBLabelPair className={" pr-md-2 flex-grow"} description={Translate("zip-code", "Zip-code")}>
                <GBInput id={"gms-zipInput"} errorMessage={ValidationHelpers.getErrorMessage("ZipCode", this)}
                  onKeyup={() => {
                    validate(this.state.PlayerData).then((e) => {
                      ValidationHelpers.addError(e, "ZipCode", this); if (e.find(e1 => e1.property == "ZipCode") == undefined) { this.findCity("keyup") } else {
                        let temp = this.state.PlayerData; temp.City = ""; this.setState({ PlayerData: temp });
                      }
                    });
                  }}
                  onblur={() => {
                    validate(this.state.PlayerData).then((e) => {
                      ValidationHelpers.addError(e, "ZipCode", this); if (e.find(e1 => e1.property == "ZipCode") == undefined) { this.findCity("blur") } else {
                        let temp = this.state.PlayerData; temp.City = ""; this.setState({ PlayerData: temp });
                      }
                    });
                  }}
                  value={this.state.PlayerData.ZipCode} onChange={(e) => { let temp = this.state.PlayerData; temp.ZipCode = e.target.value; this.setState({ PlayerData: temp }) }} className={"flex-grow"} containerClassName={"flex-grow"}></GBInput>
              </GBLabelPair>
              <GBLabelPair className={" flex-grow"} description={Translate("city", "City")}>
                <GBInput errorMessage={ValidationHelpers.getErrorMessage("City", this)}
                  value={this.state.PlayerData.City} isDisabled={this.state.HasZipCodes} onChange={(e) => { let temp = this.state.PlayerData; temp.City = e.target.value; this.setState({ PlayerData: temp }) }} className={"flex-grow"} containerClassName={"flex-grow"}></GBInput>
              </GBLabelPair>
            </div>
            <Seperator toLabelPair={true}></Seperator>
            <div className={"flex-col-to-row-lg w-100"}>
              <GBLabelPair className={" w-100 pr-lg-2 gms-left-element"} description={Translate("have_you_previous_had_a_golf_HCP_and_a_golf_union_number", "Have you previous had a golf HCP and a golf union number")+"?"}>
                <div className={"flex-row"}>
                  <GBCheckBoxSlider isChecked={this.state.showHCPField} onChange={() => { this.setState({ showHCPField: !this.state.showHCPField }) }} ></GBCheckBoxSlider>
                </div>
              </GBLabelPair>
              {this.state.showHCPField &&

                <GBLabelPair className={" w-100 gms-right-element"} description={Translate("previous_HCP_and_union_number", "Previous HCP and union number")}>
                  <GBInput className={"flex-grow"} containerClassName={"flex-grow"} onChange={(e) => { let temp = this.state.PlayerData; temp.PreviousHCP = e.target.value; this.setState({ PlayerData: temp }) }} value={this.state.PlayerData.PreviousHCP}></GBInput>
                </GBLabelPair>
              }
            </div>



          </div>
          <Seperator ></Seperator>
          <div className={" flex-col-to-row-lg flex-center gms-receipt-buttons"}>
            <Link className={"gms-button gms-first"} to={PageIndex.Home}>
              <GBGhostButton className={"w-100"} icon={IconName.arrowPrevious} highlight={true} text={Translate("fortryd", "Fortryd")} onClick={() => { }}></GBGhostButton>
            </Link>
            <div className={"gms-button gms-last"}>
              <GBGhostButton className={"w-100 "} mode={Mode.accept} icon={IconName.arrowNext} highlight={true} text={Translate("next", "Next")} onClick={() => { this.onSubmit() }}></GBGhostButton>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  updateDropDownSelection(elements: GBDropdownElements[], selectedValue): GBDropdownElements[] {
    elements.forEach(element => {
      element.selected = false;
    });
    if (selectedValue !== "") {
      elements.find((e) => { if (e.Value === selectedValue) { e.selected = true } })
    }
    return elements;
  }
  getNationalities(): Promise<GBDropdownElements[]> {
    return new Promise((resolve, reject) => {
      new AjaxHelper().simpleGet("/Public/Country?handler=Nationalities", (e) => {
        let tempNationalites: GBDropdownElements[] = [];
        if (e.data.success) {
          this.nationalitiList = e.data.data;
          e.data.data.forEach((row) => {
            tempNationalites.push(new GBDropdownElements(row.name, false, row.iso, false))
          })

          resolve(tempNationalites);
        }
      })
    })


  }


  findCity(event) {

    var _this = this;
    if(this.state.HasZipCodes){

      new AjaxHelper().simplePost("/Public/Country?handler=FindCity", { zipCode: this.state.PlayerData.ZipCode, countryIsoCode: this.state.PlayerData.Country }, (e) => {
        if (!e.data.success) {
          let tempPlayerData = _this.state.PlayerData;
          tempPlayerData.City = null;
          _this.setState({ PlayerData: tempPlayerData });
          ValidationHelpers.addCustomError(e.data.message, "ZipCode", this)
        } else {
          
          let temp = _this.state.PlayerData;
          temp.City = e.data.data.name;
          if (event == "blur") {
            temp.ZipCode = e.data.data.zipCode
          }
          _this.setState({ PlayerData: temp });
        }
      })
    }
  }

  onSubmit() {
    validate(this.state.PlayerData).then((e) => {
      e.forEach(a => { ValidationHelpers.addError(e, a.property, this) });

      if (this.state.Error.length == 0) {
        // console.log("sendt")

        this.context.setSignupFormData(new SignupFormData({
          FirstName: this.state.PlayerData.FirstName,
          LastName: this.state.PlayerData.LastName,
          Email: this.state.PlayerData.Email,
          Birthdate: this.setUTCTime(this.state.PlayerData.Birthdate),
          MobileNumber: this.state.PlayerData.MobileNumber,
          Gender: this.state.PlayerData.Gender,
          Nationality: this.state.PlayerData.Nationality,
          Country: this.state.PlayerData.Country,
          Floor: this.state.PlayerData.Floor,
          FloorSite: this.state.PlayerData.FloorSite,
          HouseLetter: this.state.PlayerData.HouseLetter,
          RoadName: this.state.PlayerData.RoadName,
          RoadNumber: this.state.PlayerData.RoadNumber,
          ZipCode: this.state.PlayerData.ZipCode,
          City: this.state.PlayerData.City,
          PreviousHCP: this.state.PlayerData.PreviousHCP
        }))
        this.props.history.push(PageIndex.Overview)
        setTimeout(() => {

        }, 50)
      }
    })

    // let data = new Date();
    // data.setFullYear(2022)
    // this.context.setSignupFormData(new SignupFormData({
    //   FirstName: "Steffen",
    //   LastName: "Andesersen",
    //   Email: "sa@golfbox.dk",
    //   MobileNumber: "12345678",
    //   Nationality: "DK",
    //   Gender: "M",
    //   Birthdate: data,
    //   RoadName: "silkeborgvej",
    //   RoadNumber: "13",
    //   HouseLetter: "B",
    //   Floor: "3",
    //   FloorSite: "th",
    //   ZipCode: "8600",
    //   City: "Silkeborg",
    //   Country: "DK",
    //   PreviousHCP: "QZÅ!!"
    // }))
    // this.props.history.push(PageIndex.Overview)

  }
  setUTCTime(input: Date) {
    return new Date(Date.UTC(input.getFullYear(), input.getMonth(), input.getDate(), input.getHours(), input.getMinutes(), input.getSeconds(), input.getMilliseconds()))
  }
}

Userdata.contextType = SignupContext;