export const ValidationHelpers = {
  getErrorMessage(fieldName: string, stateParrent: any) {
    let errorArray = stateParrent.state.Error;
    if (errorArray.length > 0 ) {
      let errorMessage = errorArray.find((e) => { if (e.PropName === fieldName) { return e } });
      if (errorMessage !== undefined) {
        return errorMessage.message
      }
    }
    return "";
  },
  addError(error, target, stateParrent: any) {
    let targetError = error.find((e) => { if (e.property === target) return e });
    let tempError: { PropName: string, message: string } = undefined;
    let tempStateErrors = stateParrent.state.Error;
    let fieldHasNoErrorInState: boolean = tempStateErrors.filter(e => { if (e.PropName === target) { return e } }).length === 0
    if (targetError !== undefined) {
      tempError = { PropName: target, message: Object.values(targetError.constraints)[Object.values(targetError.constraints).length - 1] + "" };
      if (fieldHasNoErrorInState) {
        tempStateErrors.push(tempError)
        stateParrent.setState({ Error: tempStateErrors });
        return;
      } else if (!fieldHasNoErrorInState && tempError.message === tempStateErrors.find(e => { if (e.PropName === target) { return e } }).message) {
        return;
      } else {
        let index: number = tempStateErrors.findIndex(x => x.PropName === target);
        if (index > -1) {
          tempStateErrors.splice(index, 1);
        }
        tempStateErrors.push(tempError)
        stateParrent.setState({ Error: tempStateErrors });
        return;
      }
    } else {
      if (!fieldHasNoErrorInState) {
        let index: number = tempStateErrors.findIndex(x => x.PropName === target);
        if (index > -1) {
          tempStateErrors.splice(index, 1);
        }
        stateParrent.setState({ Error: tempStateErrors });
      }
    }
  },
  addCustomError(errorMessage: string, target: string, stateParrent: any) {
    let tempStateErrors = stateParrent.state.Error;
    let tempError: { PropName: string, message: string } = undefined;
    tempError = { PropName: target, message: errorMessage };
    let fieldHasNoErrorInState: boolean = tempStateErrors.filter(e => { if (e.PropName === target) { return e } }).length === 0
    if (fieldHasNoErrorInState) {
      tempStateErrors.push(tempError)
      stateParrent.setState({ Error: tempStateErrors });
      return;
    } else if (!fieldHasNoErrorInState && tempError.message === tempStateErrors.find(e => { if (e.PropName === target) { return e } }).message) {
      return;
    } else {
      let index: number = tempStateErrors.findIndex(x => x.PropName === target);
      if (index > -1) {
        tempStateErrors.splice(index, 1);
      }
      tempStateErrors.push(tempError)
      stateParrent.setState({ Error: tempStateErrors });
      return;
    }
    
  }
  // tempStateErrors.push(tempError)
  // stateParrent.setState({ Error: tempStateErrors });
}