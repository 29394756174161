import React from 'react';
import NullCheck from 'utils/NullCheck';


export interface GBIconProps {
  Id?: string;
  className?: string;
  Icon: IconName;
  Onclick?: Function;
 }

export enum IconName{
  accept,
  cancel,
  arrowNext,
  arrowPrevious,
  arrowUp,
  arrowDown,
  increase,
  decrease,
  pencil,
  locked,
  unlocked,
  touch,
  golfIron,
  list,
  clipboard,
  calendar,
  search,
  shield,
  register,
  User,
  Help,
  Settings,
  Tools,
  Burger,
  Booking,  
  GolfBox,
  Email,
  Mobile,
  Book,
  Newspaper,
  Browser,
  GolfCource,
  Trophy,
  Gift,
  SpeechBubble,
  Trashcan,
  statusAttention,
  statusAccept,
  statusOk,
  statusCancel,
  statusWarning,
  statusInfo,
  attention,
  calculator,
  facebook,
  twitter,
  youtube,
  add,
  subtract,
  clock,
  mapPin,
  info,
  openBox,
  click,
  buggy,
  grid,
  star,
  creditcard,
  globe
}

export default class GBIcon extends React.Component<GBIconProps> {
  
  render() {
    let check = new NullCheck();
    const {
      Onclick
    } = this.props;

    let hasOnclick = check.HasValue(Onclick);
    return (
      <div id={this.props.Id} className={["gb-icon", this.getIcon(), this.props.className, hasOnclick ? "action" : null].join(' ')} onClick={hasOnclick ? () => this.props.Onclick() : null}>
            {/* Content goes here */}
      </div>
    );
  }
  getIcon(): string{
    switch (this.props.Icon) {
      case IconName.accept:         return "icon-mini-ok";
      case IconName.cancel:         return "icon-mini-cancel";
      case IconName.arrowNext:      return "icon-mini-arrow";
      case IconName.arrowPrevious:  return ["icon-mini-arrow", "rotate180"].join(' ');
      case IconName.arrowUp:        return ["icon-mini-arrow", "rotate270"].join(' ');
      case IconName.arrowDown:      return ["icon-mini-arrow", "rotate90"].join(' ');
      case IconName.increase:       return "icon-amount-increase";
      case IconName.decrease:       return "icon-amount-decrease";
      case IconName.pencil:         return "icon-pencil";
      case IconName.locked:         return "icon-padlock-locked";
      case IconName.unlocked:       return "icon-padlock-unlocked";
      case IconName.touch:          return "icon-finger-touch";
      case IconName.golfIron:       return "icon-golf-iron";
      case IconName.list:           return "icon-list";
      case IconName.clipboard:      return "icon-clipboard";
      case IconName.calendar:       return "icon-mini-calendar";
      case IconName.search:         return "icon-magnifying-glass";
      case IconName.shield:         return "icon-golf-shield";
      case IconName.register:       return "icon-payment-register";
      case IconName.User:           return "icon-profile-user";
      case IconName.Help:           return "icon-speech-bubble-help";
      case IconName.Settings:       return "icon-settings-cog";
      case IconName.Tools:          return "icon-settings-tools";
      case IconName.Burger:         return "icon-menu-burger";
      case IconName.Booking:        return "icon-time-clock-ok";
      case IconName.GolfBox:        return "icon-logo-golfbox";
      case IconName.Email:          return "icon-mail-envelope";
      case IconName.Mobile:         return "icon-mobile-phone";
      case IconName.Book:           return "icon-book";
      case IconName.Newspaper:      return "icon-newspaper";
      case IconName.Browser:        return "icon-browser";
      case IconName.Trophy:         return "icon-prize-trophy";
      case IconName.GolfCource:     return "icon-golf-course";
      case IconName.Gift:           return "icon-gift";
      case IconName.SpeechBubble:   return "icon-speech-bubble-conversation";
      case IconName.Trashcan:       return "icon-delete-trashcan";
      case IconName.statusCancel:   return "icon-status-cancel";
      case IconName.statusInfo:     return "icon-status-info";
      case IconName.statusAccept:   return "icon-status-ok";
      case IconName.statusAttention:return "icon-status-attention";
      case IconName.statusWarning:  return "icon-status-warning";
      case IconName.attention:      return " icon-mini-attention";
      case IconName.calculator:     return "icon-calculator";
      case IconName.facebook:       return "icon-logo-facebook";
      case IconName.twitter:        return "icon-logo-twitter";
      case IconName.youtube:        return "icon-logo-youtube";
      case IconName.add:            return "icon-mini-amount-increase";
      case IconName.subtract:       return "icon-mini-amount-decrease";
      case IconName.clock:          return "icon-time-clock";
      case IconName.mapPin:         return "icon-map-pin-large";
      case IconName.info:           return "icon-mini-info";
      case IconName.openBox:        return "icon-box-open";
      case IconName.click:          return "icon-finger-touch";
      case IconName.buggy:          return "icon-golf-buggy";
      case IconName.grid:           return "icon-grid";
      case IconName.star:           return "icon-star";
      case IconName.creditcard:     return "icon-payment-creditcard";
      case IconName.globe:     return "icon-globe-stand";
      default:
        return "icon-mini-arrow";
    }
  }

}

