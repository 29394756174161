import GBGhostButton from 'components/basic/GhostButton/GBGhostButton';
import { SignupContextConsumer } from 'Contexts/SignupContext';
import { PageIndex } from 'entitites/PageIndex';
import React from 'react';
import AjaxHelper from 'utils/AjaxHelper';
import { Translate } from 'utils/Language';
import { Link } from 'react-router-dom';



export interface MemberShipPacksProps {
  Id?: string;
  history?: any;
}

export interface MemberShipPacksstate {
  status: string;
  memberShipPack: MemberShipPack[];
  moreInfo: string;
  isLoading: boolean;
}
declare const window: any;
export default class MemberShipPacks extends React.Component<MemberShipPacksProps, MemberShipPacksstate> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      status: "",
      memberShipPack: undefined,
      moreInfo: undefined,
      isLoading: true
    };
    this.fetchPacks().then((e: any) => {
      let tempList: MemberShipPack[] = []
      if (e.data !== null && e.data.length > 0) {

        e.data.forEach(element => {
          tempList.push(new MemberShipPack({ Description: element.description, name: element.name, guid: element.guid, Price: element.price, selected: false, currency: element.currency, internetPaymentTerms: element.internetPaymentTerms }))
        });

      }
      this.setState({ isLoading: false, memberShipPack: tempList })
    })
  }

  fetchPacks(): Promise<MemberShipPack[]> {
    return new Promise((resolve, reject) => {
      let clubGuid = undefined;
      if (window.GMS != undefined) {
        clubGuid = window.GMS.selectedClub;
      } else {
        clubGuid = "CC17EDC4-CFD7-4733-8904-1010CE10E921";
      }
      if (clubGuid != undefined) {

        new AjaxHelper().simplePost("/public/PackOptions?clubGuid=" + clubGuid, {}, (e) => { resolve(e.data) })
      } else {
        alert("Something went wrong")
      }
    });
  }

  render() {
    if (this.state.isLoading) {
      return (<div>Loading</div>)
    }
    if (this.state.memberShipPack.length == 0) {
      return (
        <div className={"flex-grow flex-center"}>
          <span>{Translate("No_membership_packages_found_try_contacting_the_associated_club", "No membership packages found, try contacting the associated club")}</span>
        </div>
      );
    }
    return (
      <div className={"flex-column gms-pack-row"}>
        {this.state.memberShipPack.map((e, i) =>
          MemberShipPackDisplay(e, i)
        )}

      </div>
    );
  }
}

export const MemberShipPackDisplay = (pack, index, selectOption = true, horizontal = false) => {

  return (
    <div key={index} className={"gms-pack-container"}>
      <div className={"flex-col-to-row-lg flex-grow flex-center-vertical " + (horizontal ? " horizontal" : "")}>
        <div className={"flex-col-to-row-lg gms-pack-top flex-center-lg"}>
          <div className={"gms-pack-title flex-center-vertical"}>
            <span>{pack.name}</span>
          </div>
          <div className={"flex-center gms-last-child flex-column"}>
              <span className={"gms-pack-Price flex-center"}>{pack.Price.toLocaleString(undefined, { minimumFractionDigits: 2 }) + " " + pack.currency.toUpperCase() + "."}</span>
              {selectOption &&
                <div className={"gms-pack-selection flex-center flex-column"}>
                  <SignupContextConsumer>
                    {signupContext => (
                      <Link className={"remove-link-style w-100"} to={PageIndex.Userdata} >
                        <GBGhostButton className={"btn-box w-100"} text={Translate("pick_this", "Pick this")} onClick={() => { signupContext.setMemberShipPack(pack) }}></GBGhostButton>
                      </Link>
                    )}
                  </SignupContextConsumer>
                </div>
              }
          </div>
        </div>
        <div className={"gms-pack-info"} dangerouslySetInnerHTML={{ __html: pack.Description }}>
        </div>
      </div>
    </div>);
}

export class MemberShipPack {
  name: string
  Description: string;
  Price: Number;
  currency: string;
  guid: string;
  selected: boolean;
  internetPaymentTerms: string;

  constructor(init?: MemberShipPack) {
    Object.assign(this, init);
  }
}
