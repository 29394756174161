export default class NullCheck{
    StringHasValue(input: string): boolean{
        if(input !== undefined && input !== null && input !== ""){
            return true;
        } else {
            return false;
        }
    }
    NumberHasValue(input: number): boolean{
        if(input !== undefined && input !== null){
            return true;
        } else {
            return false;
        }
    }
    FunctionHasValue(input: Function):boolean{
        if(input !== undefined && input !== null){
            return true;
        } else {
            return false;
        }
    }
    HasValue(input: any){
        if(input === undefined || input === null){
            return false;
        }
        switch (typeof(input)) {
            case "string":
                return this.StringHasValue(input);
            case "number":
                return this.NumberHasValue(input);
            case "object":
            case "function":
                return this.FunctionHasValue(input);
            case "boolean":
                return input;
            default:
                    console.error('Could not reconize the type of' + input);    
                    return false;
        }
    }
}