import React from 'react';
import { CustomAttribute } from 'entitites/CustomAttribute';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import GBIcon, { IconName } from '../Icon/GBIcon';
import da from 'date-fns/locale/da';
import { format } from 'date-fns';
import NullCheck from 'utils/NullCheck';
registerLocale('da', da)
setDefaultLocale('da')
export interface GBDatePickerProps {
  Id?: string;
  Url?: string;
  Name?: string;
  DefaultDate?: Date;
  IsDisabled?: boolean;
  ShowYearDropDown?: boolean;
  setDate?: Function;
  errorMessage?: string;

}

export interface GBDatePickerstate {
  status: string;
  Time: Date;
}

export default class GBDatePicker extends React.Component<GBDatePickerProps, GBDatePickerstate> {
  testString: string = "";
  constructor(props, context) {
    super(props, context);
    this.state = {
      status: "",
      Time: this.props.DefaultDate !== undefined ? this.props.DefaultDate : new Date()
    };
  }

  render() {
    let Attribute: CustomAttribute[] = [];
    // let Check = new NullCheck();
    if (this.props.ShowYearDropDown) {
      Attribute["showYearDropdown"] = true;
      Attribute["scrollableYearDropdown"] = true;
      Attribute["yearDropdownItemNumber"] = 50;
    };
    if (this.props.DefaultDate === undefined) {
      let currentDate = new Date();
      currentDate.setFullYear(new Date().getFullYear() - 50)
      Attribute["openToDate"] = currentDate;

    }

    return (
      <div className={"fast-input-container w-100"+ (this.props.errorMessage ? " error" : "")}>

        <div className={"gms-datepicker"}>
          <DatePicker placeholderText="dd-mm-yyyy"
            onChange={date => this.submitFunction(date)}
            locale={"da"}
            dateFormat="dd-MM-yyyy"
            selected={this.props.DefaultDate === undefined ? undefined : this.props.DefaultDate}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
            {...Attribute}></DatePicker>
          <GBIcon className={"date-icon"} Icon={IconName.calendar}></GBIcon>
        </div>
        {new NullCheck().StringHasValue(this.props.errorMessage) &&
          <label>{this.props.errorMessage}</label>
        }
      </div>
    );
  }
  submitFunction(date) {
    if (this.props.setDate !== undefined) {
      this.props.setDate(date);
    } else {
      this.setState({ Time: date })
    }
  }
  componentDidMount() {

  }

}

